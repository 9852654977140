import { useEffect, useState } from "react";
import "./Profile.css";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Typography,
  Badge,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  tableCellClasses,
  Table,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { indigo, red, green, teal } from "@mui/material/colors";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { NavLink } from "react-router-dom";
import Sidenav from "../Home/Sidenav";
import useAuth from "../../hooks/useAuth";
import Appconstant from "../../Constants/Constants";

// http://localhost:8080/api/user-management/profiles/:userId

// Styled Box
const StyledBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  border: `1px solid ${theme.palette.divider}`,
  padding: "0 16px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "& .Box-content": {
    margin: "11.5px 8px",
    color: "initial",
    fontSize: "1.2rem",
  },
}));

// Styled Accordion
const Accordion = styled((props) => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .AccordionSummary-head": {
    fontSize: "1.2rem",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  "& .MuiButton-root": {
    textTransform: "initial",
  },
}));

function Profile() {
  const [usersdata, setusersdata] = useState([]);
  const userData = true;

  const { auth, setAuth } = useAuth();
  console.log(auth);
  // Accordion
  const [expanded, setExpanded] = useState("");
  const api_url = `${Appconstant.baseURL}/authpanel/profile/`;

  // useEffect(() => {
  //     fetch(api_url, { method: 'GET' }).then((response) => response.json()).then((responseData) => setusersdata(responseData))

  // }, [])
  useEffect(() => {
    let token = localStorage.getItem("token");
    fetch(api_url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Response from API:", response);
        return response.json();
      })
      .then((responseData) => setusersdata(responseData))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // handle textfield types
  const [textFieldtype, setTextFieldtype] = useState({
    oldpass: false,
    newpass: false,
    confirm_newpass: false,
  });

  const handleTextFieldType = (key) => {
    if (key === "oldpass")
      setTextFieldtype({ ...textFieldtype, oldpass: !textFieldtype.oldpass });
    else if (key === "newpass")
      setTextFieldtype({ ...textFieldtype, newpass: !textFieldtype.newpass });
    else
      setTextFieldtype({
        ...textFieldtype,
        confirm_newpass: !textFieldtype.confirm_newpass,
      });
  };

  // password change dialog variable
  const [openPasswordWindow, setOpenPasswordWindow] = useState(false);

  const handleWindowClose = () => {
    setOpenPasswordWindow(false);
    setPasswordData({
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    });
  };

  // Password data
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const handlePasswordData = (key) => (e) => {
    setPasswordData({ ...passwordData, [key]: e.target.value });
  };

  // Password handler
  // const handleChangePassword = () => {
  //     console.log(passwordData)
  //     dispatch(changePasswordData(passwordData))
  //     setOpenPasswordWindow(false)
  // }
  console.log(usersdata, "FOr usersdata");
  return (
    <>
      {/* <Sidenav /> */}
      <Stack
        direction="row"
        spacing={4}
        sx={{ marginTop: "100px", marginLeft: "350px" }}
      >
        <Paper elevation={3} className="profile-cards">
          <Box
            p={5}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Stack spacing={2} p={4} alignItems="center">
              <Box>
                <Badge
                  badgeContent=""
                  overlap="circular"
                  color="primary"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      border: `3px solid white`,
                      backgroundColor:
                        userData && userData.active
                          ? green["700"]
                          : red["A700"],
                      "&::after": {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        animation: "ripple 1s infinite ease-in-out",
                        animationDirection:
                          userData && userData.active ? "normal" : "reverse",
                        border: `2px solid ${
                          userData && userData.active
                            ? green["700"]
                            : red["A700"]
                        }`,
                        content: '""',
                      },
                    },
                    "@keyframes ripple": {
                      "0%": {
                        transform: "scale(1)",
                        opacity: 1,
                      },
                      "100%": {
                        transform: "scale(2)",
                        opacity: 0,
                      },
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: indigo[200],
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <Typography variant="h2">
                      {userData && userData.name && userData.name[0]}
                    </Typography>
                  </Avatar>
                </Badge>
              </Box>

              <Typography variant="strong" component="h3">
                {auth.name}
              </Typography>
              <Typography variant="strong" component="h5">
                {auth.email}
              </Typography>
            </Stack>
            <Box
              sx={{
                padding: "2px 8px",
                border: "2px solid var(--primary-color)",
                borderRadius: "5px",
                height: "fit-content",
              }}
            >
              <Typography variant="h6" component="span">
                <b>Role : </b>
              </Typography>
              <Typography
                variant="h6"
                component="span"
                textTransform="capitalize"
              >
                {/* {userData && userData.role} */}
                User
              </Typography>
            </Box>
          </Box>
          <Box px={5} mb={6} align="center">
            <Button
              variant="contained"
              color="warning"
              // fullWidth
              size="large"
              onClick={() => setOpenPasswordWindow(true)}
            >
              <b>Change Password</b>
            </Button>
          </Box>
        </Paper>

        {userData && userData.role === "admin" ? (
          <Paper elevation={3} className="profile-cards">
            <Box className="h-100 d-flex align-items-center rounded">
              <Stack
                direction="column"
                p={4}
                sx={{ borderRadius: "5px", flex: "100%" }}
              >
                <Typography
                  p={2}
                  className="text-center"
                  variant="h6"
                  sx={{
                    textTransform: "uppercase",
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    borderRadius: "15px 15px 0 0",
                  }}
                >
                  <b>Operations</b>
                </Typography>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordion("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography className="AccordionSummary-head">
                      Additional Roles
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-around"
                    >
                      <NavLink to="/operation/additional-roles">
                        <Button
                          color="info"
                          variant="contained"
                          size="large"
                          sx={{ backgroundColor: indigo[400] }}
                        >
                          <b>View Roles</b>
                        </Button>
                      </NavLink>
                      <NavLink to="/operation/user-roles">
                        <Button
                          color="info"
                          variant="contained"
                          size="large"
                          sx={{ backgroundColor: teal[400] }}
                        >
                          <b>User Roles</b>
                        </Button>
                      </NavLink>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordion("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography className="AccordionSummary-head">
                      Modules
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-around"
                    >
                      <NavLink to="/operation/modules">
                        <Button
                          color="info"
                          variant="contained"
                          size="large"
                          sx={{ backgroundColor: indigo[400] }}
                        >
                          <b>View Modules</b>
                        </Button>
                      </NavLink>
                      <NavLink to="/operation/role-modules">
                        <Button
                          color="info"
                          variant="contained"
                          size="large"
                          sx={{ backgroundColor: teal[400] }}
                        >
                          <b>Role Modules</b>
                        </Button>
                      </NavLink>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <StyledBox>
                  <NavLink to="/admin/all-users">
                    <Typography className="Box-content">Users</Typography>
                  </NavLink>
                </StyledBox>
                <StyledBox sx={{ borderRadius: "0 0 15px 15px" }}>
                  <NavLink to="/admin/approve">
                    <Typography className="Box-content">Approvals</Typography>
                  </NavLink>
                </StyledBox>
              </Stack>
            </Box>
          </Paper>
        ) : null}
        <Dialog open={openPasswordWindow} onClose={handleWindowClose}>
          <DialogTitle>
            <Stack direction={"row"}>
              <strong style={{ flex: "1 1 100%" }}>Change Password</strong>
              <Button
                color="error"
                variant="outlined"
                sx={{ padding: "0px 5px", minWidth: "fit-content" }}
                onClick={handleWindowClose}
              >
                <CloseRoundedIcon />
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ padding: "0 !important" }}>
            <Divider sx={{ borderColor: "black", marginBottom: 1 }} />
            <Table
              size="small"
              sx={{
                width: "auto",
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      <b>Old Password</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      type={textFieldtype.oldpass ? "text" : "password"}
                      value={passwordData.old_password}
                      onChange={handlePasswordData("old_password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle-password-visibility"
                              onClick={() => handleTextFieldType("oldpass")}
                              edge="end"
                            >
                              {textFieldtype.oldpass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      <b>New Password</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      type={textFieldtype.newpass ? "text" : "password"}
                      value={passwordData.new_password}
                      onChange={handlePasswordData("new_password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle-password-visibility"
                              onClick={() => handleTextFieldType("newpass")}
                              edge="end"
                            >
                              {textFieldtype.newpass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                      <b>Re-enter New Password</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      type={textFieldtype.confirm_newpass ? "text" : "password"}
                      value={passwordData.confirm_new_password}
                      onChange={handlePasswordData("confirm_new_password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle-password-visibility"
                              onClick={() =>
                                handleTextFieldType("confirm_newpass")
                              }
                              edge="end"
                            >
                              {textFieldtype.confirm_newpass ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={
                        passwordData.confirm_new_password.length === 0 ||
                        (passwordData.new_password.length >= 0 &&
                          passwordData.confirm_new_password.length >= 0 &&
                          passwordData.new_password ===
                            passwordData.confirm_new_password)
                          ? false
                          : true
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography
                      variant="h6"
                      className="text-muted"
                      sx={{
                        fontSize: "0.7rem",
                        transform: "translateY(-8px)",
                      }}
                    >
                      Must be same as New Password
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleChangePassword}
                      sx={{ width: "200px", marginBottom: "10px" }}
                      disabled={
                        passwordData.old_password.length > 0 &&
                        passwordData.new_password.length > 0 &&
                        passwordData.confirm_new_password.length > 0 &&
                        passwordData.new_password ===
                          passwordData.confirm_new_password
                          ? false
                          : true
                      }
                    >
                      <b>Submit</b>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </Stack>
    </>
  );
}

export default Profile;

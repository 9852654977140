import React, { useEffect, useState } from "react";
import { Paper, Stack, Toolbar, Typography, Button, Menu } from "@mui/material";
import MUIDataTable from "mui-datatables";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AppConstants from "../../Constants/Constants"

function Datatable(props) {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token")
    if(token){
      const getData = async () => {
        const url = `${AppConstants.backendURL}/paymanager/users-payment-table`;
        try {
          const response = await fetch(url, {
            method: "GET",
            headers:{
              Authorization:`Bearer ${token}`
            }
          });
          console.log(response, "forresponse");
          if (response.statusText === "OK") {
            const data = await response.json();
            console.log(data, "For data");
            setUser(data);
          } else {
            throw new Error("Request failed");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
    
  }, []);

  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  console.log(user, "For User");
  const options = {
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    selectableRowsHeader: false,
    download: false,
    print: false,
    searchPlaceholder: "Enter search key",
    searchAlwaysOpen: true,
  };

  const columns = [
    {
      name: "user_id",
      label: "user_id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "card_name",
      label: "card_name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "card_no",
      label: "card_no",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cost",
      label: "cost",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pay_email",
      label: "pay_email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pay_method",
      label: "pay_method",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "payment_active",
      label: "payment_active",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "payment_id",
      label: "payment_id",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "80%",
          float: "right",
          marginRight: "20px",
          marginTop: "80px",
        }}
      >
        <Toolbar
          mt={3}
          sx={{
            padding: "1.2rem",
            borderBottom: "4px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            Payment Methods
          </Typography>

        </Toolbar>
        <MUIDataTable
          className="m-3"
          data={user}
          columns={columns}
          options={options}
        />
      </Paper>
    </>
  );
}

export default Datatable;

import { useLocation ,Navigate,Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const RequireAuth = () =>{
    const {auth } = useAuth();
    console.log(auth, "For auth>>>>");
    const location = useLocation();
    console.log(auth.user,"Auth");

    return (
        auth? <Outlet/> :<Navigate to='/login' state={{form :location}} replace/>
    )
}

// export const unrequireAuth = () =>{
//     const {auth } = useAuth();

//     console.log(auth, "For auth>>>>");
//     const location = useLocation();
//     console.log(auth.user,"Auth");

//     return (
//         !auth? <Outlet/> :<Navigate to='/' state={{form :location}} replace/>
//     )
// }
  
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, Paper, Toolbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AppConstants from "../../Constants/Constants"
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid'
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";
import { useState } from "react";
import { useEffect } from "react";

const roles = ["Market", "Finance", "Development"];
const randomRole = () => {
  return randomArrayItem(roles);
};
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}
const initialRows = [
  {
    id: randomId(),
    name: randomTraderName(),
    age: 25,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 36,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 19,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 28,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
  {
    id: randomId(),
    name: randomTraderName(),
    age: 23,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
];

export default function FullFeaturedCrudGrid() {
  const [rowModesModel, setRowModesModel] = useState({});
  const [user, setUser] = useState([]);
  const [rows, setRows] = useState();

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (token) {
      const getData = async () => {
        const url = `${AppConstants.backendURL}/subscription-management/subscription-data`
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.statusText === "OK") {
            const data = await response.json();
            setUser(data);
            setRows(data);
          } else {
            throw new Error("Request failed");
          }
        } catch (error) { }
      };
      getData();
    }
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    let token = localStorage.getItem('token')
    const apiUrl =
      `${AppConstants.backendURL}/subscription-management/update-subscription`;

    let dataToSend = {};

    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    dataToSend.sub_id = updatedRow.sub_id;
    dataToSend.cost = updatedRow.cost;
    dataToSend.plan_name = updatedRow.plan_name;
    dataToSend.plan_validity_days = updatedRow.plan_validity_days;
    dataToSend.plan_validity_months = updatedRow.plan_validity_months;
    await axios
      .put(apiUrl, dataToSend)
      .then((response) => {
        // Handle the response data here
        console.log("Response Data:", response.data);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 120,
      editable: false,
    },
    {
      field: "sub_id",
      headerName: "SUB_ID",
      type: "number",
      width: 120,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "sub_name",
      headerName: "SUB_NAME",
      width: 190,
      editable: false,
    },
    {
      field: "cost",
      headerName: "COST",
      type: "number",
      width: 190,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "plan_name",
      headerName: "PLAN_NAME",
      width: 190,
      editable: true,
    },
    {
      field: "plan_validity_days",
      headerName: "PLAN_VALIDITY_DAYS",
      width: 190,
      editable: true,
    },
    {
      field: "plan_validity_months",
      headerName: "PLAN_VALIDITY_MONTHS",
      width: 190,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Paper
      sx={{
        height: "100%",
        width: "80%",
        float: "right",
        marginRight: "20px",
        marginTop: "80px",
        border: '1px solid lightgray'
      }}
    >
      <Toolbar
        mt={3}
        sx={{
          padding: "1.2rem",
          borderBottom: "4px solid var(--primary-color)",
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            color: "var(--primary-color)",
            fontWeight: "bold",
            paddingBottom: "40px",
            paddingTop: "20px",
          }}
          variant="h5"
          id="tableTitle"
        >
          Subscription Plans
        </Typography>
      </Toolbar>
      <DataGrid
        rows={user}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </Paper>
  );
}
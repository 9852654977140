import { Outlet } from "react-router-dom"
import useAuth from "../hooks/useAuth";
import Sidenav from "./Home/Sidenav";


const Layout = () => {
  const { auth, setAuth } = useAuth();
console.log(auth,"auth-------")
    return (
        <main className="App">
             <Sidenav/>
            <Outlet />
        </main>
    )
}
export default Layout
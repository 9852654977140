import React, { useEffect, useState } from "react";
import { Paper, Toolbar, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import AppConstants from '../../Constants/Constants.js';

function Datatable(props) {
  const [user, setUser] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (token) {
      const getData = async () => {
        const url = `${AppConstants.backendURL}/subscription-management/subscriptions-plans`
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response, "forresponse");
          if (response.statusText === 'OK') {
            const data = await response.json();
            console.log(data, "For data");
            setUser(data);
          } else {
            throw new Error('Request failed')
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, []);

  const options = {
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    selectableRowsHeader: false,
    download: false,
    print: false,
    searchPlaceholder: "Enter search key",
    searchAlwaysOpen: true
  };
  const columns = [
    {
      name: "sub_id",
      label: "sub_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sub_name",
      label: "sub_name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_description",
      label: "sub_description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "active",
      label: "active",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "80%",
          float: "right",
          marginRight: "20px",
          marginTop: "80px",
        }}
      >
        <Toolbar
          mt={3}
          sx={{
            padding: "1.2rem",
            borderBottom: "4px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            All Subscriptions
          </Typography>
        </Toolbar>
        <MUIDataTable
          className="m-3"
          data={user}
          columns={columns}
          options={options}
        />
      </Paper>
    </>
  );
}

export default Datatable;


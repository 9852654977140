import React, { useEffect, useState } from "react";
import { Paper, Stack, Toolbar, Typography, Button, Menu } from "@mui/material";
import MUIDataTable from "mui-datatables";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import PrintIcon from "@mui/icons-material/Print";
// import { CSVLink } from "react-csv";
// import jsPDF from "jspdf";
import "jspdf-autotable";
import Sidenav from "../Home/Sidenav";
import AppConstant from "../../Constants/Constants";

function Datatable(props) {
  const [user, setUser] = useState([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const url = `${AppConstant.baseURL}/user-management/profiles/table`;
      try {
        const response = await fetch(url, {
          method: "POST",
        });
        console.log(response, "forresponse");
        if (response.statusText === "OK") {
          const data = await response.json();
          console.log(data, "For data");
          setUser(data);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  console.log(user, "hellooohellooo");

  // For custom toolbar
  // console.log(props.data, "propsdata");
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  console.log(user, "For User");
  const options = {
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    selectableRowsHeader: false,
    download: false,
    print: false,
    // search: true,
    searchPlaceholder: "Enter search key",
    searchAlwaysOpen: true,
    // rowsPerPageOptions: [10, 20, 50, props.data.length],
  };

  const columns = [
    {
      name: "userID",
      label: "userID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "payment_method",
      label: "payment_method",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "payment_status",
      label: "payment_status",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const info = [
    ["Joe James", "Test Corp", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
  ];

  //  const options = {
  //    filterType: 'checkbox',\
  //  };

  console.log(user, "For user");
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "80%",
          float: "right",
          marginRight: "2%",
          marginTop: "40px",
        }}
      >
        {/* Custom Toolbar */}
        <Toolbar
          mt={3}
          sx={{
            padding: "1.2rem",
            borderBottom: "4px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
            {/* {`${props.name} `} */}
            User Management
          </Typography>
        </Toolbar>
        <MUIDataTable
          className="m-3"
          data={user}
          columns={columns}
          options={options}
        />
        {/* })
        )} */}
      </Paper>
    </>
  );
}

export default Datatable;

import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import Home from "./Components/Home/Sidenav";
import { RequireAuth, unrequireAuth } from "./Components/Requireauth";
import Sidenav from "./Components/Home/Sidenav";
import Datatable from "./Components/Datatable/Datatable";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import Layout from "./Components/Layout";
import Profile from "./Components/Profile/Profile";
// import AuthContext from "../../Context/AuthProvider "
import Payment from "./Components/Paymentmethod/Payment";
import { Navigate, useLocation } from "react-router-dom";
import Alluserdata from "./Components/Userdata/Alluserdata";
import AlluserSubs from "./Components/Userdata/AlluserSubs";
import Freeuser from "./Components/Userdata/Freeuser";
import Premium from "./Components/Userdata/Premium";
import SubscriptionGraph from "./Components/SubscriptionGraph/SubscriptionGraph";
import AllSubscriptions from "./Components/subscriptionManagement.js/AllSubscriptions.js";
import AppConstant from "./Constants/Constants";

import Dashboard from "./Components/dashboard/Dashboard";
import { PrivateRoutes, LoginRoutes } from "./Privateeroute";
import axios from "axios";
import SubscriptionPlans from "./Components/subscriptionManagement.js/SubscriptionPlans";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const seturl = location.pathname;
  const { auth, setAuth } = useAuth();
  console.log(auth, "authhhhh");
  console.log(location, "location");
  useEffect(() => {
    let token = localStorage.getItem("token");
    console.log(token, "token!");
    let redirect = location.state?.form?.pathname;
    if (token) {
      const getUser = async () => {
        try {
          const resp = await axios.get(
            `${AppConstant.baseURL}/authpanel/profile`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log(resp,"resp of getprofile");
          setAuth(resp.data);
          console.log(resp.data, "");
          navigate(redirect);
        } catch (err) {
          console.log(err, "err in getuser");
        }
      };
      getUser();
    }
  }, []);
  console.log(auth, "authhhh");
  return (
    <>
      {/* {auth ? <Sidenav /> : ''} */}
      <Routes>
        {/* <Route path="/" element={}> */}
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route
          element={
            <Layout>
              <RequireAuth />
            </Layout>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/home" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/userManagementSystem" element={<Datatable />} />
          <Route path="/paymentMethod" element={<Payment />} />
          <Route path="/all_users" element={<Alluserdata />} />
          <Route path="/allUserSubs" element={<AlluserSubs />} />
          <Route path="/freeuser" element={<Freeuser />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/SubscriptionGraph" element={<SubscriptionGraph />} />
          <Route path="/allSubscriptions" element={<AllSubscriptions />} />
          <Route path="/subscriptionPlans" element={<SubscriptionPlans />} />
        </Route>
        {/* </Route> */}
      </Routes>
    </>
  );
}

export default App;

import { useState } from "react";
import PropTypes from "prop-types";
import alladinLogo from "./Images/Alladdin_logo.png";
import useMangment from "./Images/user-management2.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
// sidenav

function Sidenav(props) {
  const { window } = props;
  const { auth, setAuth } = useAuth();
  console.log(auth);

  // LOGOUT FUNCTION
  const Handlelogout = (e) => {
    e.preventDefault();
    setAuth(false);
    console.log(auth ,'logged out');
    console.log("i am removing tokennnnn");
    localStorage.removeItem("token");
  };

  const userData = true;
  const navigate = useNavigate();

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ marginRight: "16px" }}>
            {/* <i
              className="bi bi-list toggle-sidebar-btn"
              onClick={() =>
                document
                  .querySelector("body")
                  .classList.toggle("toggle-sidebar")
              }
            ></i> */}
          </div>
          <NavLink className="logo d-flex align-items-center" to="/">
            <span className="nav-heading">
              <img className="alladinLogo" src={alladinLogo} alt="" />
            </span>
          </NavLink>
        </div>
        <div className="search-bar">
          <form
            className="search-form d-flex align-items-center justify-content-center"
            method="POST"
            action="#"
          >
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-md-none">
              <a
                className="nav-link nav-icon search-bar-toggle"
                onClick={() =>
                  document
                    .querySelector(".search-bar")
                    .classList.toggle("search-bar-show")
                }
              >
                <i className="bi bi-search"></i>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="bi bi-bell"></i>
                <span className="badge bg-danger badge-number">4</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">You have new notifications</li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>
                      <i
                        className="bi bi-clock"
                        style={{ fontSize: "13px", margin: "0 5px 0 0" }}
                      ></i>
                      30 min. ago
                    </p>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>
                      <i
                        className="bi bi-clock"
                        style={{ fontSize: "13px", margin: "0 5px 0 0" }}
                      ></i>
                      1 hr. ago
                    </p>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>
                      <i
                        className="bi bi-clock"
                        style={{ fontSize: "13px", margin: "0 5px 0 0" }}
                      ></i>
                      2 hrs. ago
                    </p>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>
                      <i
                        className="bi bi-clock"
                        style={{ fontSize: "13px", margin: "0 5px 0 0" }}
                      ></i>
                      4 hrs. ago
                    </p>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer text-center mt-2 text-decoration-underline">
                  <NavLink to="/notification/history">
                    Show all notifications
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <Avatar
                  sx={{
                    bgcolor: indigo[100],
                    width: "32px",
                    height: "32px",
                  }}
                >
                  {userData && userData.name && userData.name[0]}
                </Avatar>
                <span className="d-none d-md-block dropdown-toggle ps-2"></span>
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => navigate("/profile")}
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div onClick={Handlelogout}>
                    <a className="dropdown-item d-flex align-items-center logout-link">
                      <i className="bi bi-box-arrow-right"></i>
                      <span>Logout</span>
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
      <div id="sidebar" className="sidebar">
        <Link to="/home">
          <div className="usermangment_div">
            <div className="icon_div">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="CabinIcon"
              >
                <path d="M10 1c0 1.66-1.34 3-3 3-.55 0-1 .45-1 1H4c0-1.66 1.34-3 3-3 .55 0 1-.45 1-1h2zm2 2L6 7.58V6H4v3.11L1 11.4l1.21 1.59L4 11.62V21h16v-9.38l1.79 1.36L23 11.4 12 3zm1.94 4h-3.89L12 5.52 13.94 7zm-6.5 2h9.12L18 10.1v.9H6v-.9L7.44 9zM18 13v2H6v-2h12zM6 19v-2h12v2H6z"></path>
              </svg>
            </div>
            <div className="text_div">Home</div>
          </div>
        </Link>
        <Link to="/profile">
          <div className="usermangment_div">
            <div className="icon_div">
              <img src={useMangment} width={"30px"} className="usermanagment" />
            </div>
            <div className="text_div">Profile</div>
          </div>
        </Link>
        <Link to="/SubscriptionGraph">
          <div className="usermangment_div">
            <div className="icon_div">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="AutoGraphIcon"
              >
                <path d="M14.06 9.94 12 9l2.06-.94L15 6l.94 2.06L18 9l-2.06.94L15 12l-.94-2.06zM4 14l.94-2.06L7 11l-2.06-.94L4 8l-.94 2.06L1 11l2.06.94L4 14zm4.5-5 1.09-2.41L12 5.5 9.59 4.41 8.5 2 7.41 4.41 5 5.5l2.41 1.09L8.5 9zm-4 11.5 6-6.01 4 4L23 8.93l-1.41-1.41-7.09 7.97-4-4L3 19l1.5 1.5z"></path>
              </svg>
            </div>
            <div className="text_div">Subscription graphs</div>
          </div>
        </Link>
        <ul className="sidebar-nav" id="sidebar-nav1">
          <li className="nav-item">
            <a
              className="nav-link"
              style={{ "margin-top": "16px" }}
              data-bs-target="#xyz3-nav1"
              data-bs-toggle="collapse"
              aria-expanded="true"
            >
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ManageAccountsIcon"
              >
                <circle cx="10" cy="8" r="4"></circle>
                <path d="M10.67 13.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53V20h9.26c-.79-1.13-1.26-2.51-1.26-4 0-1.07.25-2.07.67-2.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
              </svg>
              <span className="text-capitalize">UserManagement</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="xyz3-nav1"
              className="nav-content collapse show"
              data-bs-parent="#sidebar-nav1"
            >
              <Link to="/allUserSubs">
                <li>
                  <Link className="nav-link" to="/allUserSubs">
                    <i className="bi bi-caret-right-fill"></i>
                    <span className="text-capitalize">All Users SUbscriptions</span>
                  </Link>
                </li>
              </Link>
              <Link to="/all_users">
                <li>
                  <Link className="nav-link" to="/all_users">
                    <i className="bi bi-caret-right-fill"></i>
                    <span className="text-capitalize">All Users</span>
                  </Link>
                </li>
              </Link>
              <Link to="/premium">
                <li>
                  <Link className="nav-link" to="/premium">
                    <i className="bi bi-caret-right-fill"></i>
                    <span className="text-capitalize">Premium Users</span>
                  </Link>
                </li>
              </Link>
              <Link to="/freeuser">
                <li>
                  <Link className="nav-link" to="/freeuser">
                    <i className="bi bi-caret-right-fill"></i>
                    <span className="text-capitalize">Free Users</span>
                  </Link>
                </li>
              </Link>
            </ul>
          </li>
        </ul>

        {/* SUBSCRIPTION MANAGEMENT */}
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              style={{ "margin-top": "16px" }}
              data-bs-target="#xyz3-nav"
              data-bs-toggle="collapse"
              aria-expanded="true"
            >
              {/* <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ManageAccountsIcon"
              >
                <circle cx="10" cy="8" r="4"></circle>
                <path d="M10.67 13.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53V20h9.26c-.79-1.13-1.26-2.51-1.26-4 0-1.07.25-2.07.67-2.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
              </svg> */}
              <CardMembershipIcon/>
              <span className="text-capitalize">Subscriptions</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="xyz3-nav"
              className="nav-content collapse show"
              data-bs-parent="#sidebar-nav"
            >
              <Link to="/allSubscriptions">
                <li>
                  <Link className="nav-link" to="/allSubscriptions">
                    <i className="bi bi-caret-right-fill"></i>
                    <span className="text-capitalize">All Subscriptions</span>
                  </Link>
                </li>
              </Link>
              <Link to="/subscriptionplans">
                <li>
                  <Link className="nav-link" to="/subscriptionplans">
                    <i className="bi bi-caret-right-fill"></i>
                    <span className="text-capitalize">Subscription Plans</span>
                  </Link>
                </li>
              </Link>
            </ul>
          </li>
        </ul>
        <Link to="/paymentMethod">
          <div className="usermangment_div">
            <div className="icon_div">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="PaymentIcon"
              >
                <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"></path>
              </svg>
            </div>
            <div className="text_div">Payment Method</div>
          </div>
        </Link>
        <div className="usermangment_div" onClick={Handlelogout}>
          <div className="icon_div">
            <svg
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.2929 14.2929C16.9024 14.6834 16.9024 15.3166 17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071L21.6201 12.7941C21.6351 12.7791 21.6497 12.7637 21.6637 12.748C21.87 12.5648 22 12.2976 22 12C22 11.7024 21.87 11.4352 21.6637 11.252C21.6497 11.2363 21.6351 11.2209 21.6201 11.2059L18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289C16.9024 8.68342 16.9024 9.31658 17.2929 9.70711L18.5858 11H13C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13H18.5858L17.2929 14.2929Z"
                fill="#323232"
              />
              <path
                d="M5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H14.5C15.8807 22 17 20.8807 17 19.5V16.7326C16.8519 16.647 16.7125 16.5409 16.5858 16.4142C15.9314 15.7598 15.8253 14.7649 16.2674 14H13C11.8954 14 11 13.1046 11 12C11 10.8954 11.8954 10 13 10H16.2674C15.8253 9.23514 15.9314 8.24015 16.5858 7.58579C16.7125 7.4591 16.8519 7.35296 17 7.26738V4.5C17 3.11929 15.8807 2 14.5 2H5Z"
                fill="#323232"
              />
            </svg>
          </div>
          <div className="text_div">Logout</div>
        </div>
      </div>
    </>
  );
}

Sidenav.propTypes = {
  window: PropTypes.func,
};

export default Sidenav;

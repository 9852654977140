import React, { useEffect, useState } from "react";
import { Paper, Toolbar, Typography, } from "@mui/material";
import MUIDataTable from "mui-datatables";
import AppConstants from '../../Constants/Constants.js';

function Datatable() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    let token =localStorage.getItem('token')
    if(token){
      const getData = async () => {
        const url = `${AppConstants.backendURL}/user-management/premium-users`
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response, "forresponse");
          if (response.statusText === 'OK') {
            const data = await response.json();
            console.log(data, "For data");
            setUser(data);
          } else {
            throw new Error('Request failed')
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, []);
  const options = {
    elevation: 0,
    filterType: "multiselect",
    selectableRows: "none",
    selectableRowsHeader: false,
    download: false,
    print: false,
    searchPlaceholder: "Enter search key",
    searchAlwaysOpen: true,
  };

  // Export to pdf handler
  // function exportToPdf() {

  //   doc.text(`${props.name}`, 15, 10);
  //   doc.autoTable({
  //     theme: "grid",
  //     columns: props.columns.map((row) => ({
  //       header: row.label,
  //       dataKey: row.name,
  //     })),
  //     body: props.data,
  //   });
  //   doc.save(`${props.name}.pdf`);
  // }


  const columns = [
    {
      name: "uid",
      label: "uid",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "active",
      label: "active",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: "created_at",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "updated_at",
      label: "updated_at",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "status",
      label: "status",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  

  console.log(user, "For user")
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "80%",
          float: "right",
          marginRight: "20px",
          marginTop: "80px",
        }}
      >
        {/* Custom Toolbar */}
        <Toolbar
          mt={3}
          sx={{
            padding: "1.2rem",
            borderBottom: "4px solid var(--primary-color)",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
            variant="h5"
            id="tableTitle"
          >
Premium Users</Typography>
          
        </Toolbar>
        {/* 
        {loading ? (
          <div>Loading...</div>
        ) : (
          user.map((data) => { */}
        <MUIDataTable
          className="m-3"
          data={user}
          columns={columns}
          options={options}
        />
        {/* })
        )} */}
      </Paper>
    </>
  );
}

export default Datatable;

import React from "react";
import "../../App.css";
// import logo from '../../brandmark-design (5).png'
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import AppConstant from "../../Constants/Constants";

function Singnup() {
  const { auth, setAuth } = useAuth();
  console.log(auth);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    email: "",
    role: "",
    Password: "",
    ConfrimPassword: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: "",
  });

  const [number, setnumber] = useState(false);
  const [special, setspecial] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [lengths, setlengths] = useState(false);
  const [passwordmatch, setpasswordmatch] = useState(true);
  const [emailmatch, setemailmatch] = useState(false);
  const [passfiled, setpassfiled] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const validPasswordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return validPasswordRegex.test(password); // Placeholder, replace with actual validation
  };

  const HandleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "Password") {
      console.log("password filed selected");
      setpassfiled(true);
      console.log("Password", value);
      const isvalidatepassword = validatePassword(value);
      console.log(isvalidatepassword);
      const capitalRegex = /[A-Z]/;
      const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=|]/;
      const numberRegex = /[0-9]/;
      const lengthRegex = value.length;
      console.log(lengthRegex, "lengthRegex");

      const passwordValidationResults = validatePassword(value);
      console.log(passwordValidationResults.number);

      if (capitalRegex.test(value)) {
        setuppercase(true);
      } else {
        setuppercase(false);
      }
      if (specialCharRegex.test(value)) {
        setspecial(true);
      } else {
        setspecial(false);
      }

      if (numberRegex.test(value)) {
        setnumber(true);
      } else {
        setnumber(false);
      }
      if (lengthRegex >= 8) {
        setlengths(true);
      } else {
        setlengths(false);
      }
    } else if (name === "email") {
      const isValidEmail = validateEmail(value);
      // setpassfiled(false);
      console.log(isValidEmail);
      console.log("email", value);
      setValidationMessages((prev) => ({
        ...prev,
        password: isValidEmail ? "" : "Email is not valid",
      }));
    }
  };
  const navigate = useNavigate();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(formData.email);
    const isValidPassword = validatePassword(formData.Password);
    const passwordismatch = formData.Password === formData.ConfrimPassword;
    passwordismatch ? setpasswordmatch(true) : setpasswordmatch(false);
    // console.log(passwordismatch, "password mismatch")
    // console.log(formData, "For NAme value");
    let name = formData.FirstName + " " + formData.LastName;
    console.log(name, "For NAme value");
    const password = formData.Password;
    const email = formData.email;
    const role = formData.role;

    const form_obj = {
      name,
      password,
      email,
      role,
    };

    console.log(form_obj, "For NAme value");
    if (isValidEmail && isValidPassword && passwordismatch) {
      try {
        let resp = await axios.post(
          `${AppConstant.baseURL}/authpanel/signup`,
          form_obj
        );
        console.log(resp, "for resp");
        if (resp.data.message === "user registered sucessfully") {
          setAuth(form_obj);
          navigate("/login");
        }
        // navigate('/');
      } catch (error) {
        console.log("Axios Error:", error);
        setemailmatch(true);
      }
    } else {
      console.log("Not Valid Details");
    }
  };

  return (
    <div className="main-container">
      <div className="first_box">
        <div className="nav_logo">
          {/* <img src={logo} width='120px' alt='Logo' /> */}
        </div>

        <div className="check_status">
          <h2 className="status">Check The Status</h2>
          <p className="P_tag">
            Lorem ipsum dolor sit amet consectetur <br />
            adipisicing elit. Neque repellat ipsam <br />
            dolorum sit amet consectetur adipisicingsit amet{" "}
          </p>
        </div>
      </div>
      <div className="scond_box">
        <div className="Form_div">
          <h1 className="welcome_heading">Let,s Get Started</h1>
          <form onSubmit={HandleSubmit}>
            <div className="Name">
              <div className="email_div">Email Address</div>
              <input
                type="email"
                name="email"
                onChange={HandleOnChange}
                className="Email_input"
              />
              {emailmatch ? (
                <div className="Email_check">Your Email Already Exist</div>
              ) : (
                ""
              )}
            </div>

            <div className="flex_div">
              <div className="password">
                <div className="password_div">First Name</div>
                <input
                  type="text"
                  name="FirstName"
                  onChange={HandleOnChange}
                  className="Email_input_1"
                  required
                />
              </div>

              <div className="password_1">
                <div className="password_div">Last Name</div>
                <input
                  type="text"
                  name="LastName"
                  onChange={HandleOnChange}
                  className="Email_input_2"
                  required
                />
              </div>
            </div>
            <div>
              <div className="password_div">Select User</div>
              <select
                name="role"
                id="lang"
                className="Email_input"
                onChange={HandleOnChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div className="password">
              <div className="password_div">Password</div>
              <input
                type="password"
                name="Password"
                onChange={HandleOnChange}
                className="Email_input"
              />

              {passfiled ? (
                <div className="validpasswword">
                  <div>
                    {special ? (
                      <li>One special character</li>
                    ) : (
                      <li className="true_condition">One special character</li>
                    )}
                    {uppercase ? (
                      <li>One uppercase character</li>
                    ) : (
                      <li className="true_condition">
                        One uppercase character
                      </li>
                    )}
                  </div>
                  <div>
                    {lengths ? (
                      <li>8 characters minimum</li>
                    ) : (
                      <li className="true_condition">8 characters minimum</li>
                    )}

                    {number ? (
                      <li className="uppercasee">One number</li>
                    ) : (
                      <li className="uppercasee true_condition">One number</li>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="password">
              <div className="password_div">Confrim Password</div>
              <input
                type="password"
                name="ConfrimPassword"
                onChange={HandleOnChange}
                className="Email_input"
              />
              <div>
                {passwordmatch ? (
                  <div></div>
                ) : (
                  <div className="passwordmatches">Password Does Not match</div>
                )}
              </div>
            </div>

            <button type="submit" className="btn_submit">
              Signup
            </button>
            {/* <div style={{ "marginTop": "12px" }}>
              <a href='/' style={{
                color: "black",
                marginLeft: "108px"
              }} >Forget Password</a>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Singnup;

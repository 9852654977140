import React from 'react'
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    ArcElement
);

const LineChart = (props) => {
    return (
        <Box>
            <Line
                data={props.data}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                    },
                    legend: {
                        labels: {
                            fontSize: 25,
                        },
                    },
                }}
                height={400}
            />
        </Box>
    )
}

export default LineChart;
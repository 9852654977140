import { useContext, useState } from "react";
import useAuth from "../../hooks/useAuth";
import "../../App.css";
// import logo from '../../brandmark-design (5).png'
// import Logo from '../.../'
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../Context/AuthProvider ";
import AppConstant from "../../Constants/Constants"

function Login(props) {

    const { auth, setAuth } = useAuth();
    console.log(auth);

    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    // console.log(from, "from")
    // const { auth} = useAuth();
    const [formData, setFormData] = useState({
        email: "",
        // Password: "",
    });

    const [showerror, setshowerror] = useState(false);

    const navigate = useNavigate();

    const HandleSumbit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await axios.post(
                `${AppConstant.baseURL}/authpanel/login`,
                formData
            );
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                console.log(response, 'reponseeeee')
                setAuth(formData);
                setshowerror(false);
                console.log("Auth panel", auth)
                navigate(from, { replace: true });
                
            } else {
                console.log("this is console for error");
                setAuth(false);
                setshowerror(true);
            }
            console.log(response, "For response");
        } catch (error) {
            console.error("Login Error:", error);
            setshowerror(true);
        }
    };

    const handleonchange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="main-container">
            <div className="first_box">
                <div className="nav_logo">
                </div>
                <div className="check_status">
                    <h2 className="status">Check The Status</h2>
                    <p className="P_tag">
                        Lorem ipsum dolor sit amet consectetur <br />
                        adipisicing elit. Neque repellat ipsam <br />
                        dolorum sit amet consectetur adipisicingsit amet{" "}
                    </p>
                </div>
            </div>
            <div className="scond_box">
                <div className="Form_div">
                    <h1 className="welcome_heading">Welcome To Alladdin</h1>
                    <form onSubmit={HandleSumbit}>
                        <div className="Name">
                            <div className="email_div">Email Address</div>
                            <input
                                type="email"
                                name="email"
                                onChange={handleonchange}
                                className="Email_input"
                                required
                            />
                        </div>

                        <div className="password">
                            <div className="password_div">Password</div>
                            <input
                                type="password"
                                name="password"
                                onChange={handleonchange}
                                className="Email_input"
                                required
                            />
                        </div>

                        {showerror ? (
                            <div className="error_div">
                                Email And Password Does Not Match{" "}
                            </div>
                        ) : (
                            ""
                        )}
                        <button type="submit" className="btn_submit">
                            Login
                        </button>
                        <div className="noaccount">
                            Don,t Have an account ? <a href="/signup">Signup</a>
                        </div>

                        {/* <div style={{ "marginTop": "12px" }}>
                            <a href='/' style={{
                                color: "black",
                                marginLeft: "108px"
                            }} >Forget Password</a>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;

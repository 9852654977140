import React, { useEffect, useState } from "react";
import AppConstants from "../../Constants/Constants"
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const SubscriptionGraph = () => {

  const [subname, setsubname] = useState([]);
  const [totalcost, settotalcost] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let token = localStorage.getItem('token')
      try {
        const response = await fetch(`${AppConstants.backendURL}/graphs/price-subscription`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log(response, "helooooo") 
        const newData = await response.json();
        if (Array.isArray(newData)) {
          const subnameValues = newData.map(item => item.sub_name);
          const totalcostValues = newData.map(item => item.total_cost);
          setsubname(subnameValues);
          settotalcost(totalcostValues);
        } else {
          console.error("Data is not an array:", newData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const data = {
    labels: subname,
    datasets: [
      {
        label: "Subscribers",
        data: totalcost,
        backgroundColor: ["#01137c79", "#f9b8157a", '#e5091476', '#7cad4178'],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };
  const options = {};
  return (
    <div style={{ width: "80%", margin: "75px 10px", float: "right" }}>
      <Bar data={data} options={options}></Bar>
    </div>
  );
};

export default SubscriptionGraph;
